import {
  Button,
  Flex,
  Image,
  AspectRatio,
  Box,
  Container,
  forwardRef,
  Heading,
  Input,
  Divider,
  Stack,
  Text,
  HStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Collapse,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import AppDescSection from "../components/AppDescSection";
import axios from "axios";
import { AnimateKeyframes } from "react-simple-animate";
import { saveAs } from "file-saver";
import io from 'socket.io-client';
import consteps from '../util/steps'
import apiConfig from "../config/apiConfig";

import {
  FaDownload,
  FaUpload,
} from "react-icons/fa";
import DrawRectangles from "../components/DrawRectangles";

const PreviewImage = forwardRef((props, ref) => {
  return (
    <Box
      bg="white"
      top="0"
      height="100%"
      width="100%"
      position="absolute"
      borderWidth="1px"
      borderStyle="solid"
      rounded="sm"
      borderColor="gray.400"
      as={motion.div}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      {...props}
      ref={ref}
    />
  );
});

const steps = [
  { title: "อัพโหลด", description: "" },
  { title: "ประมวลผล", description: "" },
  { title: "สำเร็จ!", description: "" },
];

const UploadComponent = ({ setActiveStep, setDecayTeethNum }) => {
  const controls = useAnimation();
  const startAnimation = () => controls.start("hover");
  const stopAnimation = () => controls.stop();
  const [selectedFile, setSelectedFile] = useState();
  const [uploadingVal, setUploadingVal] = useState(0);
  const [uploadedImg, setUploadedImg] = useState("");
  const [imageID, setImageID] = useState("");
  const [detectedImg, setDetectedImg] = useState("");
  const [detectedNum, setDetectedNum] = useState(0);
  const [socketId, setSocketId] = useState(null);
  const [detectedClasses, setDetectedClass] = useState()
  var drawRectanglesRef = useRef(null);

  const {
    isOpen: isOpenReportModal,
    onOpen: openReportModal,
    onClose: closeReportModal,
  } = useDisclosure();

  const onFileChange = (event) => {
    setDetectedImg("")
    setSelectedFile(event.target.files[0]);
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      setUploadingVal(percentComplete);
      console.log("percent: " + percentComplete);
    },
  };

  useEffect(() => {
    console.log("trying to connect socket io")
    if(!socketId){
      const socket = io(apiConfig.socketio_host);

      // Listen for the 'connect' event
      socket.on('connect', () => {
        // Access the socket ID once the connection is established
        const newSocketId = socket.id;
        console.log('Socket ID:', newSocketId);
        setSocketId(newSocketId); // Store the socket ID in state
      });

      socket.on('cavity_detection', (data) => {
        // Handle the received data from the event
        //console.log('cavity_detection:', data);
        var obj = data.message

        console.log('socket_id:'+obj.socket_id);
        console.log('socket.id:'+socket.id);
        console.log(`obj: ${JSON.stringify(obj)}`)
        setDetectedClass(obj.object_classess)

        if(`${obj.socket_id}` == socket.id){
          setDetectedImg(obj.file);
          setDetectedNum(obj.detection_num);
          setActiveStep(3);
          setDecayTeethNum(obj.detection_num);
        }
      });

      // Make sure to handle any disconnections or errors as well
      socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      socket.on('error', (err) => {
        console.error('Socket connection error:', err);
      });

      return () => {
        // Clean up the socket connection when the component unmounts
        console.log("disconnect socket id")
        //socket.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (
      selectedFile &&
      uploadingVal === 0 &&
      uploadedImg === "" &&
      detectedImg === ""
    ) {
      onFileUpload();
    }
    if (
      selectedFile &&
      uploadingVal === 100 &&
      uploadedImg !== "" &&
      detectedImg === ""
    ) {
      //console.log(`socketId: ${socketId}, uploadedImg: ${uploadedImg}, imageID: ${imageID}`)
      setActiveStep(2);
        axios
        .get(`${apiConfig.host}/test_detect`, {
          params: {
            img: `${uploadedImg}`,
            image_id: imageID,
            socket_id: socketId
          }
        })
        .then((res) => {
          var resp = res.data;
          console.log(resp)
          //alert(resp.file)
        });
    }
  }, [selectedFile, uploadingVal, uploadedImg]);

  const onFileUpload = () => {
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    //formData.append("diagnosis_id", 22);
    //formData.append("view_type", "front");

    console.log(selectedFile);
    axios.post(`${apiConfig.host}/upload_img`, formData, config).then((res) => {
      var resp = res.data;
      //alert(resp.file)

      setImageID(resp.image_id)
      setUploadedImg(resp.file);
    });
  };

  const downloadImage = () => {
    //saveAs(`${apiConfig.host}/show_img?img=${detectedImg}`, `${detectedImg}`); // Put your image url here.
    const link = document.createElement('a');
    link.download = 'image_with_rectangles.png';
    link.href = `${detectedImg}`;
    link.click();
  };

  const handleParentDownload = (ref) => {
    if (ref.current) {
        const canvas = ref.current;
        //const link = document.createElement('a');
        //link.download = 'image_with_rectangles.png';
        //link.href = canvas.toDataURL('image/png');
        //link.click();

        setDetectedImg(canvas.toDataURL('image/png'));
    }
  };

  return (
    <>
      <Modal
        onClose={closeReportModal}
        isOpen={isOpenReportModal}
        size="5xl"
        isCentered
        zIndex="2000"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ผลการตรวจจับตำแหน่งความผิดปกติโดย AI</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              cursor={"pointer"}
              alt={"Hero Image"}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              //src={`${apiConfig.host}/show_img?img=${detectedImg}`}
              src={`${detectedImg}`}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeReportModal}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Container my={{ base: "1", md: "5" }} centerContent zIndex={0}>
        {uploadingVal === 0 && (
          <AspectRatio width="80%" ratio={3 / 2}>
            <Box
              bgColor="blue.400"
              borderColor="gray.300"
              borderStyle="dashed"
              borderWidth="2px"
              rounded="md"
              shadow="sm"
              role="group"
              transition="all 150ms ease-in-out"
              _hover={{
                shadow: "md",
              }}
              as={motion.div}
              initial="rest"
              animate="rest"
              whileHover="hover"
            >
              <Box position="relative" height="100%" width="100%">
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  height="100%"
                  width="100%"
                  display="flex"
                  flexDirection="column"
                >
                  <Stack
                    pt={4}
                    height="100%"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justify="center"
                    spacing="1"
                  >
                    <Box height="20" width="14" position="relative">
                      <PreviewImage
                        variants={consteps.first}
                        backgroundImage="/images/mouth1.jpg"
                      />
                      <PreviewImage
                        variants={consteps.second}
                        backgroundImage="/images/mouth3.jpg"
                      />
                      <PreviewImage
                        variants={consteps.third}
                        backgroundImage="/images/mouth2.jpg"
                      />
                    </Box>
                    <Stack pt="4" textAlign="center" spacing="1">
                      <Heading
                        fontSize={{ base: "sm", md: "lg" }}
                        color="gray.700"
                        fontWeight="bold"
                      >
                        กดเพื่ออัพโหลดภาพ
                      </Heading>
                      <Text
                        display={{ base: "none", md: "flex" }}
                        fontWeight="light"
                      >
                        หรือวางภาพถ่ายช่องปากที่นี้
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
                <Input
                  onChange={onFileChange}
                  type="file"
                  height="100%"
                  width="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  opacity="0"
                  aria-hidden="true"
                  accept=".jpg, .jpeg, .png"
                  onDragEnter={startAnimation}
                  onDragLeave={stopAnimation}
                />
              </Box>
            </Box>
          </AspectRatio>
        )}

        {uploadingVal !== 0 && uploadingVal !== 100 && (
          <VStack>
            <CircularProgress
              value={uploadingVal}
              color="yellow.400"
              size="120px"
            >
              <CircularProgressLabel>{uploadingVal}%</CircularProgressLabel>
            </CircularProgress>
            <Text textColor="gray.300">กำอัพโหลดภาพ...</Text>
          </VStack>
        )}

        {uploadingVal === 100 && uploadedImg !== "" && detectedImg === "" && (
          <VStack w="full" h="full" m={0}>
            <AspectRatio width="full" maxW="250" ratio={2 / 1.5}>
              <AnimateKeyframes
                play
                duration={1.5}
                iterationCount="infinite"
                keyframes={[
                  { 0: "opacity: 0" }, // 0%
                  { 50: "opacity: 0.5" }, // 50%
                  { 100: "opacity: 1" }, // 100%
                ]}
                delay={1}
              >
                <Image
                  alt={"Hero Image"}
                  fit={"cover"}
                  align={"center"}
                  w={"100%"}
                  h={"100%"}
                  src={`${apiConfig.host}/show_img?img=${uploadedImg}`}
                />
              </AnimateKeyframes>
            </AspectRatio>
            <div fontFamily="mono" className="loading">
              กำลังประมวลผล...
            </div>
          </VStack>
        )}


        {detectedClasses && <DrawRectangles setCanvasRef={(ref)=>{handleParentDownload(ref)}} imageUrl={`${apiConfig.host}/show_img?img=${uploadedImg}`} data={detectedClasses} />}
        {/*<button onClick={handleParentDownload}>Download Image</button>*/}

        {uploadingVal === 100 && uploadedImg !== "" && detectedImg !== "" && (
          <Flex>
            <VStack>
              <AspectRatio width="full" ratio={3 / 2}>
                <Image
                  borderColor="gray.300"
                  borderWidth="2px"
                  rounded="md"
                  shadow="sm"
                  cursor={"pointer"}
                  alt={"Hero Image"}
                  fit={"cover"}
                  align={"center"}
                  w={"100%"}
                  h={"100%"}
                  //src={`${apiConfig.host}/show_img?img=${detectedImg}`}
                  src={`${detectedImg}`}
                  onClick={openReportModal}
                />
              </AspectRatio>
              <HStack>
                <Button
                  color="gray.700"
                  size="sm"
                  variant="ghost"
                  leftIcon={<FaDownload />}
                  onClick={downloadImage}
                >
                  ดาวน์โหลดภาพ
                </Button>
                <Button
                  color="gray.700"
                  size="sm"
                  variant="ghost"
                  leftIcon={<FaUpload />}
                  onClick={() => {
                    setDetectedImg("");
                    setUploadedImg("");
                    setUploadingVal(0);
                    setSelectedFile("");
                    setActiveStep(0);
                    setDetectedNum(0);
                    setDecayTeethNum(0);
                    setDetectedClass()
                  }}
                >
                  อัพโหลดภาพใหม่
                </Button>
              </HStack>
            </VStack>
            <Box
              display={{ base: "none", sm: "none", md: "flex" }}
              ml={{ base: 1, md: 4 }}
              p={{ base: 1, md: 4 }}
              h="full"
              minWidth={{ base: "100", sm: "100", md: "150" }}
              minHeight={180}
              w="full"
              bgColor="blue.400"
              borderColor="gray.300"
              borderStyle="dashed"
              borderWidth="2px"
              rounded="md"
              shadow="sm"
            >
              <VStack pt={{ base: 4, md: 1 }}>
                <Text
                  textColor={"black"}
                  fontSize={{ base: 14, sm: 14, md: 20 }}
                >
                  พบความเสี่ยง
                </Text>
                <Flex>
                  <Text
                    textColor={"yellow.300"}
                    fontSize={{ base: 30, sm: 30, md: 40 }}
                  >
                    {detectedNum}
                  </Text>
                </Flex>
                <Text
                  textColor={"black"}
                  fontSize={{ base: 14, sm: 14, md: 20 }}
                >
                  ตำแหน่ง
                </Text>
              </VStack>
            </Box>
          </Flex>
        )}
      </Container>
    </>
  );
};

const Home = () => {
  const [decayTeethNum, setDecayTeethNum] = useState(0);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <VStack bgColor="gray.200">
      <VStack maxWidth="7xl" bgColor={useColorModeValue("gray.100","gray.500")} minHeight="100vb">
        <Flex
          maxWidth="7xl"
          align="center"
          justifyContent={{ base: "center", md: "space-between" }}
          w="full"
          h="fit-content"
          bgGradient="linear(to-t, blue.400, blue.600)"
        >
          <Image
            display={{ base: "none", sm: "none", md: "flex" }}
            boxSize="xs"
            objectFit="cover"
            src="/images/decay.svg"
            alt="Teeth"
          />

          <Container w="full" centerContent px={{ base: 2, md: 10 }}>
            <VStack w="full">
              <UploadComponent
                setActiveStep={setActiveStep}
                setDecayTeethNum={setDecayTeethNum}
              />
              <Stepper
                index={activeStep}
                minWidth="280"
                gap="1"
                colorScheme="yellow"
                size="sm"
                pb={2}
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator size="sm">
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>
                        <Text fontSize={9}>{step.title}</Text>
                      </StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
            </VStack>
          </Container>
        </Flex>

        <Flex justify={"center"} w="full" bgColor="gray.50">
          <Collapse m={0} in={decayTeethNum > 0?true:false} animateOpacity>
            <Box w="full" color="white" shadow="md">
              <Stack
                p={3}
                align={"center"}
                direction={{
                  base: "column",
                  md: "row",
                }}
              >
                <Stack textAlign={{ base: "center", md: "center" }} pr="40px">
                <Text fontSize={18} fontWeight={600} color="red.400">พบความเสี่ยง {decayTeethNum} ตำแหน่ง</Text>{" "}
                </Stack>
                <Stack px={{base: 2, md: 0}}>
                  <Text fontSize={18} textAlign={"left"} color="gray">
                    เพื่อให้ท่านสามารถดูแลสุขภาพฟันและช่องปากได้ดีมากยิ่งขึ้น
                    ควรเข้าพบทันตแพทย์เพื่อทำการตรวจสภาพฟันและเหงือกโดยละเอียด
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Collapse>
        </Flex>

        <AppDescSection />
        <Divider />
      </VStack>
    </VStack>
  );
};

export default Home;
