import {
  Box,
  Flex,
  HStack,
  IconButton,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ButtonGroup,
  Stack,
  Collapse,
} from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import NavLink from "./NavLink";
import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaHome, FaYoutube } from "react-icons/fa";

const links = [
  { linkname: "หน้าหลัก", path: "/" },
  { linkname: "เกี่ยวกับ", path: "/about" },
  { linkname: "ติดต่อเรา", path: "/contact" },
];

const bloglinks = [
  { linkname: "ปัญหาสุขภาพฟัน", category: "tooth_problem" },
  { linkname: "การดูแลรักษาช่องปาก", category: "dent_care" },
  /*{ linkname: "All Blogs", category: "all" },
  { linkname: "Latest Blogs", category: "latest" },
  { linkname: "Europe", category: "europe" },
  { linkname: "America", category: "america" },
  { linkname: "Asia", category: "asia" },
  { linkname: "Oceania", category: "oceania" },
  { linkname: "Africa", category: "africa" },*/
];

const NavBar = () => {
  const greenColor = "#1D684A";
  const { isOpen, onClose, onOpen } = useDisclosure();
  const iconColor = useColorModeValue("white", "gray.400");
  const iconColor2 = useColorModeValue(greenColor, "gray.400");
  const navTextColor = useColorModeValue("white", "gray.200");
  const navTextWhiteBlack = useColorModeValue("black", "white");
  const navtBgWhiteBlack = useColorModeValue("white", "gray.900");
  const navTextColorDark = useColorModeValue(greenColor, greenColor);
  const navBgHoverColor = useColorModeValue("white", "blue.800");
  const navTextHOver = useColorModeValue(greenColor, "gray.200");

  const navBgColor = useColorModeValue(greenColor, "blue.900");
  const navFontSize = 15;
  const navFontWeight = "medium";

  const { colorMode, toggleColorMode } = useColorMode();
  const [isVisible, setIsVisible] = useState(true);

  const listenToScroll = () => {
    let heightToHideFrom = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isVisible]);

  return (
    <Box
      zIndex={1000}
      boxShadow="lg"
      px={0}
      sx={{
        position: "-webkit-sticky",
        /* Safari */ position: "sticky",
        top: "0",
      }}
    >
      <Collapse startingHeight={0} in={isVisible}>
        <Flex
          display={{ base: "none", md: "flex" }}
          h={50}
          pl={4}
          py={4}
          justifyContent="space-between"
          alignItems="center"
          bgColor={navtBgWhiteBlack}
        >
          <HStack>
            <Image
              boxSize="40px"
              objectFit="cover"
              src="/images/icons/moph.svg"
              alt="MOPH logo"
            />
            <Text
              fontSize={22}
              dropShadow="sm"
              fontWeight="semibold"
              bgClip='text'
              bgGradient={
                colorMode == "light"
                  ? "linear(to-t, #1D684A, #1D684A, #86AA30, #86AA30)"
                  : "linear(to-t, gray.400, white)"
              }
            >
              ระบบตรวจจับฟันผุจากภาพถ่าย
            </Text>
          </HStack>
          <Box
            bgGradient={
              colorMode == "light"
                ? "linear(to-t, #1D684A, #1D684A, #227755, #268E65, #2A9C6F)"
                : null
            }
            roundedTopLeft="md"
            roundedTopRight="md"
            h="10"
            w={300}
            mb={-5}
            mr={2}
          >
            <Text></Text>
          </Box>
        </Flex>
      </Collapse>

      <Flex
        px={{ md: 20, base: 3 }}
        bg={navBgColor}
        h={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          _hover={{ color: iconColor }}
          color={iconColor}
          bg={navBgColor}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        ></IconButton>

        <HStack>
          {/*<NavLink
            key={"home"}
            path={"/"}
            fontColor={navTextColor}
            hoverColor={navTextHOver}
            bgHoverColor={navBgHoverColor}
            fontWeight={navFontWeight}
            icon={<Icon as={FaHome} h={6} w={6} mr={1} />}
          >
            หน้าหลัก
          </NavLink>*/}

          <HStack display={{ base: "none", md: "flex" }}>
          <Icon color={"white"} as={FaHome} h={6} w={6} mr={1} />
            {links.map((link) => {
              return (
                <NavLink
                  key={link.linkname}
                  path={link.path}
                  fontColor={navTextColor}
                  hoverColor={navTextHOver}
                  bgHoverColor={navBgHoverColor}
                  fontWeight={navFontWeight}
                >
                  {link.linkname}
                </NavLink>
              );
            })}
            <Menu>
              <MenuButton
                fontSize={navFontSize}
                fontWeight={navFontWeight}
                color={navTextColor}
                p="2"
                _hover={{
                  color: navTextHOver,
                  bg: navBgHoverColor,
                }}
              >
                Blog
              </MenuButton>
              <MenuList>
                {bloglinks.map((link, index) => {
                  return (
                    <MenuItem
                      py={1}
                      px={3}
                      borderBottom={
                        index == bloglinks.length - 1 ? "0px" : "1px"
                      }
                      borderColor="gray.200"
                      key={link.linkname}
                      as={ReactLink}
                      to={`/blog/${link.category}`}
                    >
                      {link.linkname}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
        <Flex display={{base: "flex", md: "none"}} w="full" justify="center"><Text color={"white"}>ระบบตรวจจับฟันผุจากภาพถ่าย</Text></Flex>
        <HStack>
          <ButtonGroup
            pt={2}
            px={2}
            roundedTopLeft={colorMode=="light" && isVisible?"md":null}
            roundedTopRight={colorMode=="light" && isVisible?"md":null}
            bgColor={useColorModeValue("gray.200", "blue.800")}
            spacing={0}
            variant="ghost"
            mr="3"
            display={{ base: "none", md: "flex" }}
          >
            <IconButton
              color={iconColor2}
              _hover={{
                color: navTextHOver,
                bg: navBgHoverColor,
              }}
              as="a"
              href="https://www.facebook.com/anamaidoh/"
              icon={<FaFacebook fontSize="1.25rem" />}
              target="_blank"
              mr={1}
            />
            <IconButton
              color={iconColor2}
              _hover={{
                color: navTextHOver,
                bg: navBgHoverColor,
              }}
              as="a"
              href="https://twitter.com/Pr_Anamai?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              icon={<FaTwitter fontSize="1.25rem" />}
              mr={1}
            />
            <IconButton
              color={iconColor2}
              _hover={{
                color: navTextHOver,
                bg: navBgHoverColor,
              }}
              as="a"
              href="https://www.youtube.com/channel/UCS_smKnfehOcVCwvpPWlkpQ"
              target="_blank"
              icon={<FaYoutube fontSize="1.25rem" />}
            />
          </ButtonGroup>
          
          <Icon
            color={iconColor}
            cursor="pointer"
            as={colorMode === "light" ? MoonIcon : SunIcon}
            onClick={toggleColorMode}
          />
        </HStack>
      </Flex>
      {isOpen ? (
        <Box display={{ md: "none" }} backgroundColor="white" pt={2}>
          <Stack as="nav">
            {links.map((link) => {
              return (
                <NavLink
                  key={link.linkname}
                  path={link.path}
                  fontColor={navTextColorDark}
                  hoverColor={navTextHOver}
                  bgHoverColor={navBgHoverColor}
                  fontWeight={navFontWeight}
                >
                  {link.linkname}
                </NavLink>
              );
            })}
          </Stack>
          <Menu>
            <MenuButton
              fontWeight={navFontWeight}
              fontSize={navFontSize}
              px="2"
              pt="4"
              pb="2"
              textAlign="left"
              w="full"
              borderBottom={{ md: "0px", base: "1px" }}
            >
              <Text textColor={navTextColorDark}>Blog</Text>
            </MenuButton>
            <MenuList>
              {bloglinks.map((link, index) => {
                return (
                  <MenuItem
                    py={1}
                    px={3}
                    borderBottom={index == bloglinks.length - 1 ? "0px" : "1px"}
                    borderColor="gray.200"
                    key={link.linkname}
                    as={ReactLink}
                    to={`/blog/${link.category}`}
                  >
                    {link.linkname}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </Box>
      ) : null}
    </Box>
  );
};

export default NavBar;
