import { ChakraBaseProvider } from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./screens/home";
import Contact from "./screens/contact"
import AboutUs from "./screens/about"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"

import Fonts from "./Fonts"
import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "Noto",
    body: "Noto",
  },
})


const App = () => {

  return (
    <ChakraBaseProvider theme={theme}>
      <Fonts />
      <Router>
        <NavBar/>
        <main>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/about" element={<AboutUs/>}></Route>
        </Routes>
      </main>
      <Footer/>
      </Router>
    </ChakraBaseProvider>
  );
}

export default App;
